import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from '../components/layout'
import Seo from "../components/seo"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
// const H1 = props => <h1 style={{ color: "tomato" }} {...props} />
// const H2 = props => <h2 style={{ color: "saddlebrown", marginBottom: `10px` }} {...props} />
import { ListItem } from "../components/common/lists"


const components = {
  h1: props => <h1 style={{ color: "tomato" }} {...props} />,
  h2: props => <h2 style={{ color: "saddlebrown", marginBottom: `10px` }} {...props} />,
  h3: (props) => {
      return (
        <h3 style={{ 
              color: "brown", 
              textAlign: "center", 
              marginTop: "20px" 
            }}
            {...props} 
        />
      )
  },
  h4: (props) => {
      return (
        <h4 style={{ 
              marginTop: "20px" 
            }}
            {...props} 
        />
      )
  }, 
  table: (props) => {
      return (
        <table style={{ 
              borderCollapse: "collapse",
              mergin: "auto", 
              width: "80%",
              margin: "1.45rem",
            }}
            {...props} 
        />
      ) 
  },
  // th: (props) =>  {
  //   return (
  //     <th style={{
  //         borderBottom: "solid 1px #444"
  //       }}
  //       {...props} 
  //     />
  //   )
  // },
  ul: props => {
    const items = props.children.map(child => child.props.children)
    return (
      <ListItem items={items} />
    )
  }
}

const BulletinsPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes


  return (
    <Layout location={location} title={siteTitle}>
      <h1> 院内掲示一覧 </h1>
      <Seo title="院内掲示一覧" />
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          const date = post.frontmatter.date

          return (
              <post
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
                style={{display: "block", border: "3px solid", padding: "1.45rem", marginLeft: "auto", marginRight: "auto", marginBottom: `1.45rem`}}
              >
                <h1 style={{color: "saddlebrown"}} >{title}</h1>
                  <p style={{textAlign: "right"}}> {date} </p>
                <MDXProvider components={components}>
                  <MDXRenderer 
                    itemProp="articleBody"
                  >
                    {post.body}
                  </MDXRenderer>
                </MDXProvider>

              </post>
          )
        })}
    </Layout>
  )

}

export default BulletinsPage


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: {frontmatter: {tags: {eq: "院内掲示"}}}, 
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        body
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY年MM月DD日")
          title
          description
        }
      }
    }
  }
`

